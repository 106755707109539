import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DividerInput } from '@trackback/widgets';
import { MatDividerModule } from '@angular/material/divider';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'tb-divider',
  standalone: true,
  imports: [MatDividerModule, ParsePipe, AsyncPipe, MatListModule],
  template: ` <mat-divider
    [inset]="input.inset | parse: context | async"
    [vertical]="input.vertical | parse: context | async"
    [class]="input.styleClasses | parse: context | async"
  />`,
  styles: `
    tb-divider {
      display: contents;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class DividerComponent {
  @Input()
  input: DividerInput;

  @Input()
  context: Record<string, never>;
}
