import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';

@Component({
  selector: 'tb-dynamic-widget-page',
  template: `<ng-container
    [dynamicWidget]="widget$ | async"
    [context]="{}"
  ></ng-container>`,
  styles: [
    `
      :host {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DynamicWidgetDirective, DynamicWidgetDirective],
})
export class DynamicWidgetPageComponent {
  readonly widget$ = this.route.data.pipe(map(data => data.widget));

  constructor(readonly route: ActivatedRoute) {}
}
