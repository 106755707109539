import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ButtonToggleFieldInput,
  ButtonToggleFieldOutput,
  FormFieldOption,
} from '@trackback/widgets';
import { combineLatest } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { BaseFormFieldWidgetComponent } from './base-form-field-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'tb-button-toggle-field',
  template: `
    <mat-button-toggle-group
      [formControl]="_formControl"
      [required]="input.required"
      [vertical]="input.orientation === 'vertical'"
      [multiple]="input.multiple"
      (blur)="onBlur($event)"
    >
      @for (option of parsedOptions$ | async; track $index) {
        <mat-button-toggle
          [value]="option.value"
          [disabled]="option.disabled"
          >{{ option.label | parse: context | async }}</mat-button-toggle
        >
      }
    </mat-button-toggle-group>
  `,
  styles: `
    tb-button-toggle-field {
      display: inline-block;
      width: max-content;

      mat-button-toggle-group {
        width: 100%;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ReactiveFormsModule, AsyncPipe, ParsePipe, MatButtonToggleModule],
})
export default class ButtonToggleFieldComponent
  extends BaseFormFieldWidgetComponent<
    ButtonToggleFieldInput,
    ButtonToggleFieldOutput
  >
  implements OnInit
{
  @HostBinding('style.paddingTop')
  paddingTop?: string;

  @HostBinding('style.paddingRight')
  paddingRight?: string;

  @HostBinding('style.paddingBottom')
  paddingBottom?: string;

  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  public readonly parsedOptions$ = combineLatest([
    this.input$,
    this.context$,
  ]).pipe(
    switchMap(([input, context]) =>
      this._parser.parse(input && input.options, {
        context,
        log:
          !this._config || !this._config.PRODUCTION ? console.log : undefined,
      })
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  async ngOnInit() {
    await super.ngOnInit();
    this.parsedOptions$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(parsedOptions => {
        const options = parsedOptions as FormFieldOption[];
        return this.updateOutput({ options: options });
      });
  }
}
