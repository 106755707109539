/**
 * Form fields provide a consistent way to collect user input.
 *
 * <i>Hint:</i> It can be helpful to define an alias for the form field to make it easier to use with bindings that generate form payloads.
 *
 * Form fields support the following local actions:
 * - SetValue: Manually set [[FormFieldOutputModel.value]] at runtime
 * - SetDisabledState: Manually set [[FormFieldInputModel.disabled]] at runtime
 * - MarkAsSubmitted: Sets the current value as default value and marks the form field as pristine and untouched
 *
 * @module widgets/form-field
 * @preferred
 */

/** Required comment to display module description, wont be included in the documentation */

import { Directive, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  FormFieldInputModel,
  FormFieldOutputModel,
  isExpression,
  LocalActionModel,
} from '@trackback/widgets';
import { isEqual } from 'lodash-es';
import { firstValueFrom, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';

import { ValidatorErrorModel } from '@trackback/widgets/build/main/widgets/validators';
import { FormFieldValidatorService } from '../services/form-field-validator.service';
import { WidgetsState } from '../state/widgets/widgets.state';
import { BaseWidgetComponent } from './base-widget.component';

/**
 * @ignore
 */
@Directive()
export abstract class BaseFormFieldWidgetComponent<
    I extends FormFieldInputModel,
    O extends FormFieldOutputModel,
  >
  extends BaseWidgetComponent<I, O>
  implements OnInit
{
  public _formControl: UntypedFormControl = new UntypedFormControl();
  private readonly _validator: FormFieldValidatorService;

  get firstFormControlError() {
    const errors = (this._formControl || {}).errors;
    if (
      errors &&
      typeof errors === 'object' &&
      Object.keys(errors).length > 0
    ) {
      const error = Object.values(errors)[0];
      if (error && typeof error === 'object') {
        return error as ValidatorErrorModel;
      } else if (error === true) {
        return {
          name: 'required',
          type: 'required',
          errorMessage: 'This field is required', // TODO: Make compatible with ng-lang
        } as ValidatorErrorModel;
      } else {
        return null;
      }
    }
    return null;
  }

  set _value(value: any) {
    this._formControl.setValue(value);
    this._formControl.markAsDirty();
    this._cd.markForCheck();
  }

  async setDefaultValue(value: any) {
    await this.updateOutput({
      defaultValue: this.internalToExternal(value),
      touched: false,
    } as Partial<O>);
    this._formControl.markAsUntouched();
    const currentOutput = (this._store.selectSnapshot(
      WidgetsState.getWidgetOutput(this.id)
    ) || {}) as Partial<O>;
    if (
      !this.input.syncedValueOverDefault ||
      this.externalToInternal(currentOutput.value) === undefined
    ) {
      this._value = value;
    }
  }

  set _disabled(flag: boolean) {
    if (flag && this._formControl.enabled) {
      this._formControl.disable();
    } else if (!flag && this._formControl.disabled) {
      this._formControl.enable();
    }
    this._cd.markForCheck();
  }

  constructor(injector: Injector) {
    super(injector);
    this._validator = injector.get(FormFieldValidatorService);
  }

  /**
   * Overridable method used to perform transformations of default values.
   * The default behaviour is to perform no transformations at all.
   */
  protected externalToInternal(next: any) {
    return next;
  }

  /**
   * Overridable method used to perform transformations of value changes before they are published.
   * The default behaviour is to perform no transformations at all.
   */
  protected internalToExternal(next: any) {
    return next;
  }

  /**
   * The amount of time to debounce value changes.
   * Can be overridden by child classes like text fields to implement debounce behaviour.
   *
   * @default 0
   */
  protected defaultDebounceTime(): number {
    return 0;
  }

  /**
   * This method should be called by all implementing widget types when a blur event is fired by the input.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onBlur(event?: Event) {
    this.updateOutput({
      touched: true,
    } as Partial<O>);
  }

  async ngOnInit() {
    await this.parseId();
    this.init();

    // Add form field class to all form field types
    this.addStyleClasses('form-field');

    // Initialise Form Control
    const currentOutput = (this._store.selectSnapshot(
      WidgetsState.getWidgetOutput(this.id)
    ) || {}) as Partial<O>;
    const currentValue = this.externalToInternal(currentOutput.value);
    const hadOutputValuePresent = currentValue !== undefined;
    const shouldSyncValue =
      hadOutputValuePresent &&
      (this.input.defaultValue === undefined ||
        this.input.syncedValueOverDefault);
    const initialDisabledState =
      typeof this.input.disabled === 'boolean' ? this.input.disabled : false;
    const parsedDefaultValue = await firstValueFrom(
      this.parse(this.input.defaultValue)
    );
    const initialValue = shouldSyncValue
      ? currentValue
      : this.externalToInternal(parsedDefaultValue);
    const currentDefaultValue = shouldSyncValue
      ? currentOutput.defaultValue
      : parsedDefaultValue;

    const validators = this.input.validators || [];

    this._formControl = new UntypedFormControl(
      {
        value: initialValue,
        disabled: initialDisabledState,
      },
      {
        validators: this.input.required ? Validators.required : null,
        asyncValidators:
          validators.length > 0
            ? this._validator.createAsyncValidator(validators)
            : null,
      }
    );

    // Necessary for the validators to gain access to the widget and its currently active context at the time of validation
    this._formControl['widget'] = this.input;
    this._formControl['contextFactory'] = () => this.context;

    // Set dirty/touched state on formcontrol if value is synced
    if (shouldSyncValue) {
      if (currentOutput.dirty) {
        this._formControl.markAsDirty();
      }
      if (currentOutput.touched) {
        this._formControl.markAsTouched();
      }
      // Revalidating the Angular formcontrol forces the view to reflect the synced state
      this._formControl.updateValueAndValidity();
    }

    // Register the widget with potentially synced values
    await this.register(
      {
        value: this.internalToExternal(initialValue),
        defaultValue: currentDefaultValue,
        errors: this._formControl.errors,
        enabled: !this._formControl.disabled,
        pending: this._formControl.pending,
        valid: this._formControl.errors === null,
        dirty: this._formControl.dirty,
        touched: this._formControl.touched,
        changed: currentOutput.changed,
      } as O,
      false
    );

    // Create Async Subscriptions
    // This block has to come after registration due to a potential race-condition between registration and defaultValue bindings

    // FormControl -> State

    // > Watch for value changes to sync store
    let firstChange = true;
    const debounceTimeMs =
      (typeof this.input.debounceTime === 'number' &&
        this.input.debounceTime) ||
      this.defaultDebounceTime();
    let debouncedFormattedValue$ = this._formControl.valueChanges.pipe(
      map(next => this.internalToExternal(next))
    );
    if (debounceTimeMs > 0) {
      debouncedFormattedValue$ = debouncedFormattedValue$.pipe(
        debounceTime(debounceTimeMs)
      );
    }
    debouncedFormattedValue$
      .pipe(
        distinctUntilChanged(isEqual), // Precaution to prevent multiple executions of changeActions
        takeUntil(this.destroyed$)
      )
      .subscribe(val => {
        const defaultValue = (
          this._store.selectSnapshot(WidgetsState.getWidgetOutput(this.id)) ||
          {}
        ).defaultValue;
        const shouldExecuteChangeAction =
          this.input.changeAction &&
          !(this.input.skipFirstChangeAction && firstChange);
        // If there is a change action to be executed, we cannot batch the output update
        // because otherwise the action would be executed before the next output batch is
        // performed and output data would not be up-to-date for usage in the action payload.
        // tslint:disable-next-line: max-line-length
        // TODO: updateOutput should return a promise that we can wait on before we dispatch the change action, then the output can be always batched
        this.updateOutput(
          {
            value: val,
            dirty: true,
            changed: this.hasChanged(defaultValue, val),
          } as Partial<O>,
          !shouldExecuteChangeAction
        );
        if (shouldExecuteChangeAction) {
          this.dispatchActionsPromise(this.input.changeAction);
        }
        firstChange = false;
      });

    this._formControl.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.updateOutput({
          errors: this._formControl.errors,
          pending: this._formControl.pending,
          valid: this._formControl.errors === null,
          enabled: this._formControl.enabled,
        } as Partial<O>);
        this._cd.markForCheck();
      });

    // Input -> FormControl

    // > Watch for disabled state changes to sync form control
    if (isExpression(this.input.disabled)) {
      this.parse(this.input.disabled)
        .pipe(distinctUntilChanged())
        .subscribe(flag => (this._disabled = flag as boolean));
    }

    // > Parse defaultValue if it could be a binding
    if (this.input.defaultValue !== undefined) {
      this.parse(this.input.defaultValue)
        .pipe(
          filter((val, index) =>
            shouldSyncValue && currentDefaultValue !== undefined
              ? index > 1
              : true
          ),
          map(val => this.externalToInternal(val)),
          distinctUntilChanged(isEqual)
        )
        .subscribe(newValue => this.setDefaultValue(newValue));
    }
  }

  hasChanged(defaultValue, actualValue) {
    if (
      defaultValue === actualValue ||
      ((defaultValue === null || defaultValue === undefined) &&
        (actualValue === null || actualValue === undefined))
    ) {
      return false;
    }
    return !isEqual(defaultValue, actualValue);
  }

  // Actions

  handleResetAction(action: LocalActionModel) {
    let value = action.payload;
    if (value === undefined) {
      const currentOutput = (this._store.selectSnapshot(
        WidgetsState.getWidgetOutput(this.id)
      ) || {}) as Partial<O>;
      value = currentOutput.defaultValue;
    }
    this._formControl.setValue(this.externalToInternal(value));
    this._formControl.markAsUntouched();
    this._formControl.markAsPristine();
    this.updateOutput({
      dirty: false,
      changed: false,
    } as Partial<O>);
    this._cd.markForCheck();
    return of(null);
  }

  handleSetValueAction(action: LocalActionModel) {
    this._value = action.payload;
    return of(null);
  }

  handleSetDisabledStateAction(action: LocalActionModel) {
    this._disabled = action.payload as boolean;
    return of(null);
  }

  /**
   * Sets the default value to the current value and marks the form control as pristine and untouched.
   */
  handleMarkAsSubmittedAction() {
    this.setDefaultValue(this._formControl.value);
    this.updateOutput({
      dirty: false,
      changed: false,
    } as Partial<O>);
    this._formControl.markAsUntouched();
    this._formControl.markAsPristine();
    this._cd.markForCheck();
    return of(null);
  }
}
