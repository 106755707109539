import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { ListSubheaderInput } from '@trackback/widgets';
import { MatListModule } from '@angular/material/list';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'tb-list-subheader',
  standalone: true,
  imports: [DynamicWidgetDirective, MatListModule, ParsePipe, AsyncPipe],
  template: `
    <div matSubheader [class]="input.styleClasses | parse: context | async">
      @for (child of input.children; track $index) {
        <ng-container [dynamicWidget]="child" [context]="context" />
      }
    </div>
  `,
  styles: `
    tb-list-subheader {
      display: contents;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ListSubheaderComponent {
  @Input()
  input: ListSubheaderInput;

  @Input()
  context: Record<string, never>;
}
