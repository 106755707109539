import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { NavigationListInput, NavigationListOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Register('NavigationList')
@Component({
  selector: 'tb-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgFor, NgIf, DynamicWidgetDirective, AsyncPipe, ParsePipe],
})
export class NavigationListComponent
  extends BaseWidgetComponent<NavigationListInput, NavigationListOutput>
  implements OnInit, OnDestroy
{
  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}
