import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { ListItemInput } from '@trackback/widgets';
import { AsyncPipe } from '@angular/common';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'tb-list-item',
  standalone: true,
  imports: [DynamicWidgetDirective, MatListModule, AsyncPipe, ParsePipe],
  template: `
    <mat-list-item
      [class]="input.styleClasses | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
    >
      @if (input.avatar) {
        <div matListItemAvatar>
          <ng-container [dynamicWidget]="input.avatar" [context]="context" />
        </div>
      }
      @if (input.icon) {
        <div matListItemIcon>
          <ng-container [dynamicWidget]="input.icon" [context]="context" />
        </div>
      }
      @if (input.title) {
        <div matListItemTitle>
          <ng-container [dynamicWidget]="input.title" [context]="context" />
        </div>
      }
      @if (input.lines) {
        @for (line of input.lines; track $index) {
          <div matListItemLine>
            <ng-container [dynamicWidget]="line" [context]="context" />
          </div>
        }
      }
      @if (input.meta) {
        <div matListItemMeta>
          <ng-container [dynamicWidget]="input.meta" [context]="context" />
        </div>
      }
    </mat-list-item>
  `,
  styles: `
    tb-list-item {
      display: contents;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ListItemComponent {
  @Input()
  input: ListItemInput;

  @Input()
  context: Record<string, never>;
}
