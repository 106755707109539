tb-navigation-list {
  display: flex;
  flex-direction: column;
  &.minimized {
    min-width: 64px;
  }

  // for scrollbar hiding
  overflow-y: scroll;
  &::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  // can pass class side-nav-0 to side-nav-4 for menu indentation
  @for $i from 0 through 4 {
    .side-nav-#{$i} {
      .mat-button, .mat-flat-button {
        text-align: left;
        padding-left: #{($i *16) + 16}px;
      }
    }
  }
}
