import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { TextFieldInput, TextFieldOutput } from '@trackback/widgets';
import { takeUntil } from 'rxjs/operators';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Register('TextField')
@Component({
  selector: 'tb-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyFormFieldModule,
    NgIf,
    MatLegacyInputModule,
    ReactiveFormsModule,
    MatLegacyButtonModule,
    MatIconModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class TextFieldComponent
  extends BaseFormFieldWidgetComponent<TextFieldInput, TextFieldOutput>
  implements OnInit
{
  hide: boolean;
  actionDisabled: boolean;
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @see {@link https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be}
   */
  defaultDebounceTime() {
    return 500;
  }
  async ngOnInit() {
    await super.ngOnInit();
    this.hide = this.input.fieldType === 'password';
    if (this.input.actionDisabled) {
      this.parse(this.input.actionDisabled)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(actionDisabled => (this.actionDisabled = !!actionDisabled));
    } else {
      this.actionDisabled = false;
    }
  }

  onEnter() {
    if (!this.actionDisabled && this.input.enterAction) {
      this.updateOutput({ actionPending: true }, false);
      this.dispatchActionsPromise(this.input.enterAction).finally(() =>
        this.updateOutput({ actionPending: false }, false)
      );
    }
  }
}
