{
  "name": "tb-app-ui",
  "version": "8.12.0",
  "scripts": {
    "start": "npx ng serve",
    "serve:dev": "npx ng serve",
    "serve:prod": "npx ng serve --configuration production",
    "build:prod": "npx ng build --configuration production",
    "test": "npx cross-env ng test",
    "lint": "npx ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular-material-components/file-input": "16",
    "@angular/animations": "^17.3.11",
    "@angular/cdk": "^16.2.11",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/localize": "^17.3.11",
    "@angular/material": "^16.2.11",
    "@angular/material-date-fns-adapter": "^16.2.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@kolkov/angular-editor": "3.0.0-beta.0",
    "@ngxs/devtools-plugin": "3.8.1",
    "@ngxs/logger-plugin": "3.8.1",
    "@ngxs/router-plugin": "3.8.1",
    "@ngxs/storage-plugin": "3.8.1",
    "@ngxs/store": "3.8.1",
    "@trackback/styles": "~1.1.0",
    "@trackback/widgets": "8.4.1-feature-ops-hours-admin-tool.27",
    "angularx-qrcode": "^16.0.2",
    "chart.js": "4",
    "chartjs-plugin-datalabels": "^2.2.0",
    "classlist.js": "^1.1.20150312",
    "core-js": "^2.6.12",
    "date-fns": "^2.30.0",
    "date-fns-tz": "2",
    "file-saver": "^2.0.5",
    "html2canvas": "^1.4.1",
    "immer": "^10.0.2",
    "jspdf": "^2.5.1",
    "lodash-es": "^4.17.21",
    "material-icons": "^1.8.2",
    "ng2-charts": "5",
    "ngx-mat-select-search": "6",
    "rxjs": "^6.5.3 || ^7.4.0",
    "tslib": "^2.0.0",
    "typeface-roboto": "0.0.54",
    "web-animations-js": "^2.3.2",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
    "zone.js": "~0.14.7",
    "type-fest": "^4.35.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.5.2",
    "@angular-eslint/eslint-plugin": "17.5.2",
    "@angular-eslint/eslint-plugin-template": "17.5.2",
    "@angular-eslint/schematics": "17.5.2",
    "@angular-eslint/template-parser": "17.5.2",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@angular/language-service": "^17.3.11",
    "@types/file-saver": "^2.0.5",
    "@types/jest": "^29.5.8",
    "@types/lodash-es": "4.17.3",
    "@types/node": "^12.19.9",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "cross-env": "^7.0.3",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-prettier": "^5.0.1",
    "jest": "^29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "^14.0.3",
    "ng-packagr": "^17.3.0",
    "prettier": "^3.0.3",
    "prettier-eslint": "^16.1.2",
    "ts-essentials": "^10.0.0",
    "ts-expect": "^1.3.0",
    "ts-node": "~7.0.0",
    "typescript": ">=5.2.0 <5.5.0"
  },
  "jest-junit": {
    "outputDirectory": "./junit"
  }
}
