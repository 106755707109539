import {
  ComponentRef,
  Directive,
  input,
  IterableChangeRecord,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TrackByFunction,
  ViewContainerRef,
} from '@angular/core';
import { WidgetInputModel } from '@trackback/widgets';
import { isEqual } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { WidgetDefinitionTuple } from '@app/models/widget-input.model';
import { WidgetResolverService } from '@app/services/widget-resolver.service';
import { BaseWidgetComponent } from '../widgets/base-widget.component';
import { getTypeAsync } from '@app/utils/type-registry';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dynamicWidget]',
  standalone: true,
})
export class DynamicWidgetDirective implements OnChanges, OnDestroy {
  dynamicWidget = input.required<WidgetInputModel>();
  context = input.required<Record<string, never>>();

  private _widgets: Array<ComponentRef<BaseWidgetComponent<never, never>>> = [];
  private _currentTask: Promise<unknown>;
  private _taskQueue: Array<() => Promise<unknown>> = [];
  private _destroy$ = new Subject<void>();
  private _input$ = new Subject<WidgetInputModel | undefined>();
  private _context$ = new Subject<Record<string, never> | undefined>();
  private _differ: IterableDiffer<WidgetInputModel> | null = null;
  private trackBy: TrackByFunction<WidgetInputModel> = (
    _index: number,
    widget?: WidgetInputModel
  ) => {
    return widget && widget.id && widget.type
      ? `${widget.id}-${widget.type}`
      : widget;
  };
  constructor(
    private widgetResolver: WidgetResolverService,
    private differs: IterableDiffers,
    private container: ViewContainerRef
  ) {
    this.widgetResolver
      .resolve(combineLatest([this._input$, this._context$]))
      .pipe(distinctUntilChanged(isEqual), takeUntil(this._destroy$))
      .subscribe((widgetDefinitions: WidgetDefinitionTuple[]) => {
        this._taskQueue.push(() => this.update(widgetDefinitions));
        this.runNextTask();
      });
  }

  private runNextTask() {
    if (this._currentTask) {
      return;
    }
    const taskExecutor = this._taskQueue.shift();
    if (taskExecutor) {
      this._currentTask = taskExecutor()
        .catch(console.error)
        .finally(() => {
          // Execute next queue item
          delete this._currentTask;
          this.runNextTask();
        });
    }
  }

  private update = async (widgetDefinitions: WidgetDefinitionTuple[]) => {
    // Exclude undefined defintions (e.g an if with a falsy condition and no elseWidget)
    widgetDefinitions = widgetDefinitions.filter(([input]) => !!input);

    // Check for changes in the widgets to be rendered and update accordingly
    const newInputs = widgetDefinitions.map(([input]) => input);
    const componentChanges = this.checkDiffers(newInputs);
    if (componentChanges) {
      await this._applyChanges(componentChanges);
    }
    // Check the contexts and update accordingly
    const widgets = this.widgets;
    for (let i = 0; i < widgets.length; i++) {
      const widget = widgets[i];
      const newContext = widgetDefinitions[i][1];
      const previousContext = widget.instance.context;
      if (previousContext !== newContext) {
        widget.setInput('context', newContext || {});
      }
    }
  };

  private _applyChanges(changes: IterableChanges<WidgetInputModel>) {
    const promises = [];
    changes.forEachOperation((...args) =>
      promises.push(this._applyChange(...args))
    );
    return Promise.all(promises);
  }

  private _applyChange = async (
    record: IterableChangeRecord<WidgetInputModel>,
    adjustedPreviousIndex: number,
    currentIndex: number
  ) => {
    if (record.previousIndex == null) {
      const input = record.item;
      const component = this.container.createComponent(
        await getTypeAsync<BaseWidgetComponent<never, never>>(input.type),
        {
          index: currentIndex,
        }
      );
      component.setInput('input', input);
      this._widgets = [...this._widgets];
      this._widgets.splice(currentIndex, 0, component);
    } else if (currentIndex == null) {
      this.container.remove(adjustedPreviousIndex);
      this._widgets = [...this._widgets];
      this._widgets.splice(adjustedPreviousIndex, 1);
    } else {
      const view = this.container.get(adjustedPreviousIndex);
      if (view) {
        this.container.move(view, currentIndex);
        this._widgets = [...this._widgets];
        const [item] = this._widgets.splice(adjustedPreviousIndex, 1);
        this._widgets.splice(currentIndex, 0, item);
      }
    }
  };

  get widgets() {
    return this._widgets;
  }

  checkDiffers(newValue: WidgetInputModel[]) {
    if (!this._differ) {
      this._differ = this.differs.find(newValue).create(this.trackBy);
    }
    return this._differ.diff(newValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.dynamicWidget) {
      this._input$.next(changes.dynamicWidget.currentValue);
    }
    if (changes.context) {
      this._context$.next(changes.context.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
