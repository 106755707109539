import { Register } from '@app/utils/type-registry';
import { ForeachInputModel, WidgetInput } from '@trackback/widgets';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetDefinitionTuple } from '@app/models/widget-input.model';
import { ParserService } from '@app/services/parser.service';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';
import { APP_CONFIG } from '@app/models/app-config.model';

@Register('foreach', STRUCTURAL_WIDGET_TYPE_GROUP)
export class ForeachWidget extends WidgetResolver<
  ForeachInputModel<WidgetInput, Record<string, unknown>>
> {
  private readonly _parser = this._injector.get(ParserService);
  private readonly _config = this._injector.get(APP_CONFIG, null);

  getState(
    context?: Record<string, unknown>
  ): Observable<WidgetDefinitionTuple[]> {
    return this._parser
      .parse(this._input.in, {
        context: context,
        log:
          !this._config || !this._config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        map(objects =>
          objects && Array.isArray(objects) && objects.length > 0
            ? objects.map((obj, idx) => {
                const ctx = {
                  ...context,
                  [this._input.dataAlias || 'foreachData']: obj,
                  [this._input.metaAlias || 'foreachMeta']: {
                    index: idx,
                    first: idx === 0,
                    last: idx === objects.length - 1,
                    odd: idx % 2 !== 0,
                    even: idx % 2 === 0,
                    total: objects.length,
                  },
                };
                return [this._input.template, ctx] as WidgetDefinitionTuple;
              })
            : []
        )
      );
  }
}
