import { register, registerAsync } from '@app/utils/type-registry';

// Synchronous Widgets
import './widgets/audio/audio.component';
import './widgets/button/button.component';
import './widgets/check-box/check-box-field.component';
import './widgets/chip-grid/chip-grid.component';
import './widgets/chip-row/chip-row.component';
import './widgets/data-table/data-table.component';
import './widgets/datepicker-field/datepicker-field.component';
import './widgets/default-page/default-page.component';
import './widgets/dialog/dialog.component';
import './widgets/directional-layout/directional-layout.component';
import './widgets/expansion-panel/expansion-panel.component';
import './widgets/file-upload-field/file-upload-field.component';
import './widgets/hyperlink/hyperlink.component';
import './widgets/icon/icon.component';
import './widgets/image/image.component';
import './widgets/label-row/label-row.component';
import './widgets/navigation-list/navigation-list.component';
import './widgets/progress-spinner/progress-spinner.component';
import './widgets/radio-group-field/radio-group-field.component';
import './widgets/search-box-field/search-box-field.component';
import './widgets/select-field/select-field.component';
import './widgets/slide-toggle-field/slide-toggle-field.component';
import './widgets/tabs/tabs.component';
import './widgets/text-field/text-field.component';
import './widgets/text/text.component';
import './widgets/textarea-field/textarea-field.component';
import './widgets/texthtml/texthtml.component';
import './widgets/toolbar/toolbar.component';
import './widgets/tree-table/tree-table.component';
import './widgets/accordion/accordion.component';
import './widgets/paginator/paginator.component';
import './widgets/chips-field/chips-field.component';
import './widgets/expansion-panel-table/expansion-panel-table.component';
import './widgets/insert-remove-animate.component';
import './widgets/progress-bar.component';
import './structural-widgets/conditional-widget';
import './structural-widgets/data-widget';
import './structural-widgets/data-store';
import './structural-widgets/foreach-widget';
import './structural-widgets/switch-widget';

import Fragment from '@app/widgets/fragment.component';
import DividerComponent from '@app/widgets/divider.component';
import ListComponent from '@app/widgets/list.component';
import ListItemComponent from '@app/widgets/list-item.component';
import ListSubheaderComponent from '@app/widgets/list-subheader.component';
import ButtonToggleFieldComponent from '@app/widgets/button-toggle-field.component';

// Sync Widgets
register('Fragment', Fragment);
register('Divider', DividerComponent);
register('List', ListComponent);
register('ListItem', ListItemComponent);
register('ListSubheader', ListSubheaderComponent);
register('ButtonToggleField', ButtonToggleFieldComponent);

// Async Widgets
registerAsync('chart', () => import('./widgets/chart.component'));
registerAsync('Editor', () => import('./widgets/editor.component'));
registerAsync('qrcode', () => import('./widgets/qr-code.component'));
