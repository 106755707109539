<ng-container *ngFor="let item of input.items; first as isFirst">
  <ng-container *ngIf="item.type === 'NavigationListSection'; else link">
    <h3 *ngIf="!item.widget">
      {{ item.text | parse: context | async }}
    </h3>
    <ng-container
      [dynamicWidget]="item.widget"
      [context]="context"
    ></ng-container>
    <ng-container *ngIf="item.collapse | parse: context | async">
      <ng-container *ngFor="let subItem of item.children">
        <ng-container
          *ngIf="subItem.type === 'NavigationListSection'; else sub1"
        >
          <h3 *ngIf="!subItem.widget">
            {{ subItem.text | parse: context | async }}
          </h3>
          <ng-container
            [dynamicWidget]="subItem.widget"
            [context]="context"
          ></ng-container>
          <ng-container *ngIf="subItem.collapse | parse: context | async">
            <div
              *ngFor="let sub of subItem.children"
              [dynamicWidget]="sub"
              [context]="context"
            ></div>
          </ng-container>
        </ng-container>
        <ng-template #sub1>
          <div [dynamicWidget]="subItem" [context]="context"></div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #link>
    <ng-container [dynamicWidget]="item" [context]="context"></ng-container>
  </ng-template>
</ng-container>
