import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { TextareaFieldInput, TextareaFieldOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Register('TextareaField')
@Component({
  selector: 'tb-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyFormFieldModule,
    NgIf,
    MatLegacyInputModule,
    ReactiveFormsModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class TextareaFieldComponent extends BaseFormFieldWidgetComponent<
  TextareaFieldInput,
  TextareaFieldOutput
> {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @see {@link https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be}
   */
  defaultDebounceTime() {
    return 500;
  }
}
