import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { ListInput } from '@trackback/widgets';
import { AsyncPipe } from '@angular/common';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'tb-list',
  standalone: true,
  imports: [DynamicWidgetDirective, MatListModule, AsyncPipe, ParsePipe],
  template: `
    <mat-list
      [class]="input.styleClasses | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
    >
      @for (child of input.children; track $index) {
        <ng-container [dynamicWidget]="child" [context]="context" />
      }
    </mat-list>
  `,
  styles: `
    tb-list {
      display: contents;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ListComponent {
  @Input()
  input: ListInput;

  @Input()
  context: Record<string, never>;
}
